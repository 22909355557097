<template>
    <v-tooltip v-if="date !== undefined" :text="tooltipText">
        <template v-slot:activator="{ props }">
            <span v-bind="props" class="timeAgo">
                <span v-if="prepend">{{ prepend }}&nbsp;</span><strong>{{ distanceToNow }}</strong>
            </span>
        </template>
    </v-tooltip>
</template>

<script lang="ts" setup>
import { formatDateForTimezone } from '@/lib/utils'
import { formatDistanceToNowStrict } from 'date-fns'
import { computed } from 'vue'

const props = defineProps({
    date: {
        type: Date,
        required: false
    },
    timezoneId: {
        type: String,
        required: false
    },
    prepend: {
        type: String,
        required: false
    },
    appendSuffix: {
        type: Boolean,
        default: true
    }
})

const distanceToNow = computed(() => {
    if (props.date === undefined) return undefined

    // const seconds = differenceInSeconds(props.date, new Date())
    // if (seconds <= 0) return 'imminent'

    return formatDistanceToNowStrict(props.date, { addSuffix: props.appendSuffix })
})

const tooltipText = computed(() => {
    if (props.date === undefined) return undefined
    return formatDateForTimezone({ date: props.date, timezoneId: props.timezoneId })
})
</script>

<style lang="scss" scoped>
.timeAgo {
    padding: 2px 4px;
    cursor: pointer;
    border-radius: 3px;

    position: relative;
    left: -3px;

    &:hover {
        background-color: rgba(0, 0, 200, 0.1);
    }
}
</style>
