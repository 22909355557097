<template>
    <v-menu v-model="showMenu" open-on-hover>
        <template v-slot:activator="{ props }">
            <div v-bind="props" class="readingCell" :class="[statusBackgroundColor(reading), statusTextColor(reading), excludedClass]">
                <h3 class="title">{{ title }}</h3>
                <h1 :class="[statusTextColor(reading)]">{{ reading.value }}</h1>
            </div>
        </template>

        <v-card>
            <v-card-text>
                <div class="status">
                    <v-row no-gutters class="mb-3">
                        <v-col cols="auto" class="text-left"
                            ><h2>{{ reading.title }}</h2></v-col
                        >
                        <v-col v-if="reading.date" class="text-right"
                            ><h3 class="date">{{ formatDateForVesselTimezone({ date: reading.date, vesselTimezoneId }) }}</h3></v-col
                        >
                    </v-row>

                    <span v-if="isExcludedReading"> EXCLUDED: {{ excludedReadingReason }} </span>

                    <span v-else-if="readingStatus !== undefined" class="text-left">
                        <v-chip v-if="readingStatus === ReadingStatuses.ReallyLow" color="red">Really Low</v-chip>
                        <v-chip v-else-if="readingStatus === ReadingStatuses.Low" color="orange">Low</v-chip>
                        <v-chip v-else-if="readingStatus === ReadingStatuses.Ok" color="blue">OK</v-chip>
                        <v-chip v-else-if="readingStatus === ReadingStatuses.High" color="orange">High</v-chip>
                        <v-chip v-else-if="readingStatus === ReadingStatuses.ReallyHigh" color="red">Really High</v-chip>
                        <v-chip v-else-if="readingStatus === ReadingStatuses.Invalid" color="gray">INVALID</v-chip>
                        <v-chip v-else-if="readingStatus === ReadingStatuses.Unknown" color="gray">UNKNOWN</v-chip>
                        <v-chip v-else-if="readingStatus !== ReadingStatuses.Ok" color="gray">{{ readingStatus }}</v-chip>
                        <div
                            v-if="
                                readingStatus !== ReadingStatuses.Invalid &&
                                readingStatus !== ReadingStatuses.Unknown &&
                                readingStatus !== ReadingStatuses.Ok &&
                                statusSinceDate !== undefined
                            "
                            class="statusHold"
                            :class="statusTextColor(reading)"
                        >
                            for {{ formatDistanceToNowStrict(statusSinceDate) }}
                        </div>

                        <div v-if="readingStatus === ReadingStatuses.Invalid && 'statusReason' in reading" class="statusHold" :class="statusTextColor(reading)">
                            {{ reading.statusReason }}
                        </div>

                        <div
                            v-if="showStatusHold && 'statusHold' in reading && reading?.statusHold?.description"
                            class="statusHold"
                            :class="statusTextColor(reading)"
                        >
                            {{ reading.statusHold.description }}
                        </div>
                    </span>
                </div>
            </v-card-text>
        </v-card>
    </v-menu>
</template>

<script lang="ts" setup>
import { ref, type PropType } from 'vue'
import { ReadingStatuses, type ReadingV1 } from '@general-galactic/crystal-api-client'
import type { ExcludedReadingWithTitle } from '@/lib/readings'
import { formatDateForVesselTimezone } from '@/lib/utils'
import { computed } from 'vue'
import { formatDistanceToNowStrict } from 'date-fns'

const showMenu = ref(false)

const props = defineProps({
    reading: {
        type: Object as PropType<ReadingV1 | ExcludedReadingWithTitle>,
        required: true
    },
    vesselTimezoneId: {
        type: String,
        required: false
    },
    showStatusHold: {
        type: Boolean,
        default: false
    }
})

const title = computed(() => {
    if ('abbreviation' in props.reading) return props.reading.abbreviation
    return props.reading.title
})

const isExcludedReading = computed<boolean>(() => {
    return 'exclusionReason' in props.reading
})

const excludedReadingReason = computed<string | undefined>(() => {
    if ('exclusionReason' in props.reading) return props.reading.exclusionReason
    return undefined
})

const reading = computed<ReadingV1 | undefined>(() => {
    if ('exclusionReason' in props.reading) return undefined
    return props.reading
})

const statusSinceDate = computed(() => {
    return reading.value?.statusSinceDate
})

const readingStatus = computed<ReadingStatuses | undefined>(() => {
    return reading.value?.status
})

const statusTextColor = (reading: ReadingV1 | ExcludedReadingWithTitle) => {
    if ('exclusionReason' in reading) return 'text-gray'

    if (reading.status === ReadingStatuses.Ok) return 'text-blue'
    if (reading.status === ReadingStatuses.High || reading.status === ReadingStatuses.Low) return 'text-orange'
    if (reading.status === ReadingStatuses.ReallyHigh || reading.status === ReadingStatuses.ReallyLow) return 'text-red'
    return 'text-gray'
}

const excludedClass = computed(() => {
    return isExcludedReading.value ? 'excluded' : undefined
})

const statusBackgroundColor = (reading: ReadingV1 | ExcludedReadingWithTitle) => {
    if ('exclusionReason' in reading) return 'bg-gray-lighten-5'

    if (reading.status === ReadingStatuses.Ok) return 'bg-blue-lighten-5'
    if (reading.status === ReadingStatuses.High || reading.status === ReadingStatuses.Low) return 'bg-orange-lighten-5'
    if (reading.status === ReadingStatuses.ReallyHigh || reading.status === ReadingStatuses.ReallyLow) return 'bg-red-lighten-5'
    return 'bg-gray-lighten-5'
}
</script>

<style lang="scss" scoped>
.readingCell {
    display: inline-block;
    margin-top: 6px;
    margin-right: 6px;
    margin-bottom: 6px;
    min-width: 40px;
    padding: 0 3px;
    border: 0.5px solid #ddd;
    border-radius: 6px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    text-align: center;
    transition: 0.3s all;

    &:hover {
        transform: scale3d(1.1, 1.1, 1.1);
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
        cursor: pointer;
    }

    h3.title {
        font-weight: 400;
        font-size: 14px;
    }

    h1 {
        font-weight: 700;
        font-size: 20px;
    }
}

.status {
    text-align: left;

    h2 {
        margin-right: 20px;
    }

    h3.date {
        font-weight: 400;
    }

    .statusHold {
        font-size: 14px;
        margin-top: 4px;
    }
}

.excluded {
    H1 {
        text-decoration: line-through;
    }
}
</style>
