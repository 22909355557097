<template>
    <v-menu>
        <template v-slot:activator="{ props }">
            <v-card v-if="account.type === AccountTypesV1.Pro" color="green-darken-2" variant="flat">
                <v-card-item>
                    <div>
                        <div class="text-overline">Pro Account</div>
                        <div class="text-h6">{{ account.name }}</div>
                    </div>
                </v-card-item>
                <v-card-actions>
                    <div class="text-caption ml-3"><time-ago prepend="since" :date="account.typeSince" :append-suffix="true" class="ml-2"></time-ago></div>
                    <v-spacer></v-spacer>
                    <v-btn color="medium-emphasis" icon="mdi-dots-vertical" v-bind="props"></v-btn>
                </v-card-actions>
            </v-card>

            <v-card v-else-if="account.type === AccountTypesV1.Consumer" color="blue-darken-2" variant="flat">
                <v-card-item>
                    <div>
                        <div class="text-overline">Consumer Account</div>
                    </div>
                </v-card-item>
                <v-card-actions>
                    <div class="text-caption ml-3"><time-ago prepend="since" :date="account.typeSince" :append-suffix="true" class="ml-2"></time-ago></div>
                    <v-spacer></v-spacer>
                    <v-btn color="medium-emphasis" icon="mdi-dots-vertical" v-bind="props"></v-btn>
                </v-card-actions>
            </v-card>
        </template>

        <v-list>
            <v-list-item v-if="account.type === AccountTypesV1.Pro" title="Change Account Name" @click="showChangeAccountNameModal = true">
                <template v-slot:prepend>
                    <v-icon color="blue-darken-1" icon="mdi-pencil"></v-icon>
                </template>
            </v-list-item>
            <v-list-item title="Change Account Type" @click="showChangeAccountTypeModal = true">
                <template v-slot:prepend>
                    <v-icon color="blue-darken-1" icon="mdi-pencil"></v-icon>
                </template>
            </v-list-item>
        </v-list>
    </v-menu>

    <v-dialog v-model="showChangeAccountTypeModal" :min-width="800" :width="1000">
        <v-card>
            <v-card-title>
                <h3>Change Account Type</h3>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col>
                            <p>Warning. Changing the account type will cause trouble. Make sure you know what you're doing.</p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-select v-model="newAccountType" label="Account Type" :items="[AccountTypesV1.Consumer, AccountTypesV1.Pro]"></v-select>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="gray-darken-1" @click="showChangeAccountTypeModal = false" size="large">Cancel</v-btn>
                <v-btn color="red-darken-1" @click="updateAccountType" size="large">Change Account Type</v-btn>
            </v-card-actions>
            <v-overlay contained :model-value="changingAccountType" class="align-center justify-center">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
        </v-card>
    </v-dialog>

    <v-dialog v-model="showChangeAccountNameModal" :min-width="800" :width="1000">
        <v-card>
            <v-card-title>
                <h3>Change Account Name</h3>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col>
                            <v-text-field v-model="newAccountName" label="Account Name" clearable></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="gray-darken-1" @click="showChangeAccountNameModal = false" size="large">Cancel</v-btn>
                <v-btn color="red-darken-1" @click="updateAccountName" size="large">Save Account Name</v-btn>
            </v-card-actions>
            <v-overlay contained :model-value="changingAccountName" class="align-center justify-center">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
        </v-card>
    </v-dialog>
</template>

<script lang="ts" setup>
import type { PropType } from 'vue'
import { AccountTypesV1, type AccountDetailsV1 } from '@general-galactic/crystal-api-client'
import { ref } from 'vue'
import TimeAgo from '@/components/TimeAgo.vue'
import { useApi } from '@/api'
import { handleApiError } from '@/lib/utils'

const props = defineProps({
    accountId: {
        type: Number,
        required: true
    },
    account: {
        type: Object as PropType<AccountDetailsV1>,
        required: true
    }
})

const emit = defineEmits(['changed-account-type', 'changed-account-name'])

const showChangeAccountTypeModal = ref(false)
const showChangeAccountNameModal = ref(false)

const changingAccountType = ref(false)
const changingAccountName = ref(false)

const newAccountType = ref<AccountTypesV1>(props.account.type)
const newAccountName = ref<string | undefined>(props.account.name)

const updateAccountType = () => {
    console.log('Changing account type to: ', newAccountType.value)
    handleApiError(
        async () => {
            changingAccountType.value = true
            await useApi().changeAccountTypeV1({
                accountId: props.accountId,
                putAccountTypeRequestV1: {
                    accountType: newAccountType.value
                }
            })
            console.log('Changed account type to: ', newAccountType.value)
            showChangeAccountTypeModal.value = false
            emit('changed-account-type')
        },
        async () => {
            changingAccountType.value = false
        }
    )
}

const updateAccountName = () => {
    console.log('Changing account name to: ', newAccountName.value)
    handleApiError(
        async () => {
            changingAccountName.value = true
            await useApi().changeAccountNameV1({
                accountId: props.accountId,
                putAccountNameRequestV1: {
                    accountName: newAccountName.value
                }
            })
            console.log('Changed account name to: ', newAccountName.value)
            showChangeAccountNameModal.value = false
            emit('changed-account-name')
        },
        async () => {
            changingAccountName.value = false
        }
    )
}
</script>
