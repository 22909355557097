<template>
    <div v-if="reading" class="readingCell">
        <span v-if="showDeleteReadingButton" class="engineeringOptions">
            <v-btn icon="mdi-delete" size="small" color="red" @click="deleteReading()"></v-btn>
            <v-btn icon="mdi-alarm" size="small" color="red" @click="expireReading()"></v-btn>
        </span>

        <div class="readingCellContent">
            <div class="left">
                <div class="titleSection">
                    <v-tooltip :text="`${reading.title} - ${reading.description}`">
                        <template v-slot:activator="{ props }">
                            <h1 v-bind="props">{{ title }}</h1>
                        </template>
                    </v-tooltip>
                </div>
            </div>
            <div class="center">
                <div class="status">
                    <v-chip v-if="reading?.status === ReadingStatuses.ReallyLow" color="red" size="small">Really Low</v-chip>
                    <v-chip v-else-if="reading?.status === ReadingStatuses.Low" color="orange" size="small">Low</v-chip>
                    <v-chip v-else-if="reading?.status === ReadingStatuses.Ok" color="blue" size="x-small">OK</v-chip>
                    <v-chip v-else-if="reading?.status === ReadingStatuses.High" color="orange" size="small">High</v-chip>
                    <v-chip v-else-if="reading?.status === ReadingStatuses.ReallyHigh" color="red" size="small">Really High</v-chip>
                    <v-chip v-else-if="reading?.status === ReadingStatuses.Invalid" color="gray" size="small">invalid</v-chip>
                    <!-- <v-chip v-else-if="reading?.status === ReadingStatuses.Unknown" color="gray" size="small">unknown</v-chip> -->

                    <div
                        v-if="
                            reading?.status !== ReadingStatuses.Invalid &&
                            reading?.status !== ReadingStatuses.Unknown &&
                            reading?.status !== ReadingStatuses.Ok &&
                            reading.statusSinceDate !== undefined
                        "
                        class="statusHold"
                        :class="statusTextColor(reading.status)"
                    >
                        for {{ formatDistanceToNowStrict(reading.statusSinceDate) }}
                    </div>

                    <div v-if="reading?.status === ReadingStatuses.Invalid" class="statusHold" :class="statusTextColor(reading.status)">
                        {{ reading.statusReason }}
                    </div>
                    <v-tooltip v-if="reading?.statusHold?.description" :text="`expires in ${reading.statusHold.timeRemaining}`">
                        <template v-slot:activator="{ props }">
                            <div class="statusHold hoverable" :class="statusTextColor(reading.status)" v-bind="props">
                                {{ reading.statusHold.description }}
                            </div>
                        </template>
                    </v-tooltip>
                </div>
            </div>
            <div class="right">
                <div v-if="reading" class="value" :class="[statusBackgroundColor(reading.status), statusTextColor(reading.status)]">
                    <h3 class="title">{{ showRaw ? 'AVERAGE' : rawTitle }}</h3>
                    <h1 :class="statusTextColor(reading.status)">{{ reading.value }}</h1>
                    <h3 class="date" v-if="date">
                        <v-tooltip :text="formatDateForVesselTimezone({ date, vesselTimezoneId })">
                            <template v-slot:activator="{ props }">
                                <span v-bind="props">{{ formatDistanceToNowStrict(date, { addSuffix: true }) }}</span>
                            </template>
                        </v-tooltip>
                    </h3>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { formatDistanceToNowStrict } from 'date-fns'
import { computed, type PropType } from 'vue'
import { ReadingStatuses, ReadingTypesV1, type ReadingV1 } from '@general-galactic/crystal-api-client'
import { useApi } from '@/api'
import { useGeneralStore } from '@/stores/general'
import { formatDateForVesselTimezone } from '@/lib/utils'

const props = defineProps({
    readingType: {
        type: String,
        required: true
    },
    title: {
        type: String,
        required: true
    },
    reading: {
        type: Object as PropType<ReadingV1>,
        required: false
    },
    showRaw: {
        type: Boolean,
        default: false
    },
    rawTitle: {
        type: String,
        default: 'REPORTED'
    },
    accountId: {
        type: Number
    },
    vesselId: {
        type: Number
    },
    vesselTimezoneId: {
        type: String
    }
})

const date = computed(() => {
    if (props.reading) return props.reading.date
    return undefined
})

const emit = defineEmits(['updatedReading'])

const generalStore = useGeneralStore()

const showDeleteReadingButton = computed(() => {
    return generalStore.$state.showEngineeringOptions
})

const statusTextColor = (status: ReadingStatuses) => {
    if (status === ReadingStatuses.Ok) return 'text-blue'
    if (status === ReadingStatuses.High || status === ReadingStatuses.Low) return 'text-orange'
    if (status === ReadingStatuses.ReallyHigh || status === ReadingStatuses.ReallyLow) return 'text-red'
    return 'text-gray'
}

const statusBackgroundColor = (status: ReadingStatuses) => {
    if (status === ReadingStatuses.Ok) return 'bg-blue-lighten-5'
    if (status === ReadingStatuses.High || status === ReadingStatuses.Low) return 'bg-orange-lighten-5'
    if (status === ReadingStatuses.ReallyHigh || status === ReadingStatuses.ReallyLow) return 'bg-red-lighten-5'
    return 'bg-grey-lighten-4'
}

const deleteReading = async () => {
    if (!props.accountId || !props.vesselId || !props.reading?.source) return
    try {
        await useApi().deleteVesselStatusReadingV2({
            expire: false,
            accountId: props.accountId,
            vesselId: props.vesselId,
            readingSource: props.reading?.source,
            readingType: props.readingType as ReadingTypesV1
        })
        console.log('Deleted reading')
        emit('updatedReading')
    } catch (error) {
        console.error('Error deleting reading', error)
    }
}

const expireReading = async () => {
    if (!props.accountId || !props.vesselId || !props.reading?.source) return
    try {
        await useApi().deleteVesselStatusReadingV2({
            expire: true,
            accountId: props.accountId,
            vesselId: props.vesselId,
            readingSource: props.reading?.source,
            readingType: props.readingType as ReadingTypesV1
        })
        console.log('Expired reading')
        emit('updatedReading')
    } catch (error) {
        console.error('Error deleting reading', error)
    }
}
</script>

<style lang="scss" scoped>
.engineeringOptions {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -20px;
    margin-left: -40px;
    z-index: 10;

    button {
        margin-right: 10px;
    }
}

.readingCell {
    padding: 0;
    border: 0.5px solid #ddd;
    min-width: 300px;
    border-radius: 6px;
    position: relative;
    margin-bottom: 10px;
    overflow: hidden;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    max-width: 320px;
}

h1 {
    font-weight: 700;
    font-size: 30px;
}

.showsDate {
    .value {
        padding-bottom: 20px;
    }
}

.statusHold {
    font-size: 11px;
    border-radius: 10px;
    padding: 0 4px;

    &.hoverable:hover {
        background-color: rgba(0, 0, 0, 0.05);
        cursor: pointer;
    }
}

.readingCellContent {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    align-items: center;

    .left {
        height: 100%;
        flex-grow: 2;
        padding: 6px 0 2px 6px;

        .titleSection {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        .status {
            text-align: center;
            margin: 0 6px;
        }

        h1 {
            padding: 4px 0 4px 0;
            font-weight: 700;
            font-size: 30px;
        }

        .date {
            text-align: left;
            font-size: 12px;
            color: #444;
            font-weight: 300;
        }
    }

    .center {
        padding: 0 6px;
        text-align: center;
    }

    .right {
        min-height: 100%;
        flex-grow: 0;

        .value {
            text-align: center;
            min-width: 80px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 0 6px;

            H3.title {
                text-transform: uppercase;
                color: #666;
                font-weight: 500;
                font-size: 11px;
                letter-spacing: 1.2px;
            }

            h1 {
                font-weight: 00;
                font-size: 30px;
                margin: 5px 0;
            }

            H3.date {
                font-weight: 400;
                font-size: 12px;
            }
        }
    }
}
</style>
